:root {
  --danger: #f44336;
  --theme: #003F73;
  --section: #f4f9ff;
  --gray: #64748B;
  --maroon: #BF2026;
}

@font-face {
  font-family: "Muli-Regular";
  src: url("assets/fonts/Muli-Regular.woff") format('woff');
}

@font-face {
  font-family: "Muli-Bold";
  src: url("assets/fonts/Muli-Bold.woff") format('woff');
}

* {
  font-family: Muli;
}
body {
  overflow-x: hidden !important;
  font-family: "Roboto", sans-serif;
  margin: 0;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.themeBtn {
  background-color: var(--theme) !important;
  border-color: var(--theme) !important;
  color: white !important;
}

.themeBtn:disabled {
  background-color: #F8F9FB !important;
  border-color: #9DACB6 !important;
  color: black !important;
}