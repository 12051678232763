.loaderCont {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 64px);
    overflow-y: scroll;
}
.other-ans-container{
    display: flex;
    flex-direction: row;
}

.question-image{
    width: 100%;
    height: auto;
    @media only screen and (max-width: 400px) {
        height: 100px;
    }
}

.questIndex_container{
    display: flex;
    .questRequired{
        color: #003F73 ;
    }
}

.questionText{
    width: '100%';
    font-family: 'Frank Ruhl Libre';
    word-break: break-word;
}

.list_of_exmpl_wrapper{
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-left: 2rem;
    margin-top: 0.5rem;
    .exmpl_item{
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }
}
