.circularProgress {
    text-align: center;
}

/* width */
::-webkit-scrollbar {
    width: 7px;
  }
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: #003F73;
    border-radius: 5px;
  }

.button_action, .disabled-button {
    color: white !important;
    background-color: #FF7D50 !important;
}

.disabled-button, .button_action.disable {
    background-color: grey !important;
}

.content_body {
    min-height: 100vh;
    overflow-y: auto;
}

.textAlignment {
    text-align: center;
}

.divAlignment {
    align-items: center;
}

.loader { 
    z-index: 5 ;
    position: fixed !important;
    width: 100% !important;
    top: 48px !important;
  }

.display-loader {
    visibility: hidden;
    }

.display-loader.active {
      visibility: visible;
    }

.MuiRating-root {
    color: #F9602C !important;
    line-height: 40px;
    margin-right: 10px;
}

.MuiRating-root.Mui-disabled {
    opacity: 1 !important;
}