.loaderCont {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 64px);
    overflow-y: scroll;
}

.formSect {
    margin: auto;
    max-width: 800px;
    align-items: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    @media only screen and (max-width: 650px) {
        padding-top: 20px;
    }
}

form {
    display: flex;
    flex-direction: column;

    button {
        align-self: center;
    }
}

.text-area-style {
    width: 96%;
    margin-left: 26px;
    margin-bottom: 12px;
}

.ratingStars {
    margin: 10px 40px;
}

.star-count-container {
    margin-left: 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 1.4rem;

    @media only screen and (max-width: 650px) {
        gap: 1rem;
    }

    .star-rating {
        color: #70757a;
        font-size: 16px;
        height: 16px;
        position: relative;
        text-shadow: 0 1px 0 #70757a;
    }
}

.MuiRating-root {
    display: flex;
    gap: 0.25rem;
}

.optionText{
    word-break: break-word;
}


.MuiRadio-colorSecondary.Mui-checked {
        color: var(--theme) !important;
   }

   .makeStyles-formlabel-2 span{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;

   }

   .MuiFormGroup-root{
    display: flex;
    flex-direction: row !important;
   }